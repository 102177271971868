<template>
  <div class="card">
    <div class="card-header">
      <a :href="'https://github.com/milanowicz/' + name" target="_blank">{{ name }}</a>
    </div>
    <img v-if="phpLogo" src="../assets/images/php-logo.png" class="card-img-top">
    <img v-if="npmLogo" src="../assets/images/npm-logo-red.png" class="card-img-top">
    <img v-if="pythonLogo" src="../assets/images/python-logo-inkscape.svg" class="card-img-top">
    <img v-if="bashLogo" src="../assets/images/bash_full_colored_light.svg" class="card-img-top">
    <div class="card-body">
      <ul>
        <li v-if="build">
          <img :src="'https://github.com/milanowicz/' + name + '/workflows/' + buildName + '/badge.svg?branch=' + branch">
        </li>
        <li v-if="coverageMutation">
          <img :src="'https://img.shields.io/endpoint?style=flat&url=https%3A%2F%2Fbadge-api.stryker-mutator.io%2Fgithub.com%2Fmilanowicz%2F' + name + '%2Fmaster'">
        </li>
        <li v-if="coverageCode">
          <img :src="'https://codecov.io/gh/milanowicz/' + name + '/branch/master/graph/badge.svg'">
        </li>
        <li v-if="tag && !coverageMutation && !coverageCode">
          <img :src="'https://img.shields.io/github/v/tag/milanowicz/' + name">
        </li>
        <li v-if="license && !coverageMutation && !coverageCode">
          <img :src="'https://img.shields.io/github/license/milanowicz/' + name">
        </li>
        <li v-if="!coverageMutation && !coverageCode">
          <img :src="'https://img.shields.io/github/last-commit/milanowicz/' + name">
        </li>
      </ul>
      <ul v-if="coverageCode || coverageMutation">
        <li v-if="tag">
          <img :src="'https://img.shields.io/github/v/tag/milanowicz/' + name">
        </li>
        <li v-if="license">
          <img :src="'https://img.shields.io/github/license/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/github/last-commit/milanowicz/' + name">
        </li>
      </ul>
      <ul v-if="jsBadges">
        <li>
          <img :src="'https://raw.githubusercontent.com/milanowicz/' + name + '/' + branch + '/.github/badge-branches.svg'">
        </li>
        <li>
          <img :src="'https://raw.githubusercontent.com/milanowicz/' + name + '/' + branch + '/.github/badge-functions.svg'">
        </li>
        <li>
          <img :src="'https://raw.githubusercontent.com/milanowicz/' + name + '/' + branch + '/.github/badge-lines.svg'">
        </li>
        <li>
          <img :src="'https://raw.githubusercontent.com/milanowicz/' + name + '/' + branch + '/.github/badge-statements.svg'">
        </li>
      </ul>
      <ul>
        <li>
          <img :src="'https://img.shields.io/github/languages/top/milanowicz/' + name">
        </li>
        <li v-if="php">
          <img :src="'https://img.shields.io/packagist/php-v/milanowicz/' + name">
        </li>
        <li v-if="npmName.length > 0">
          <img :src="'https://img.shields.io/node/v-lts/' + npmName">
        </li>
      </ul>
      <ul v-if="php">
        <li>
          <img :src="'https://img.shields.io/packagist/v/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/packagist/dt/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/packagist/dd/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/packagist/dm/milanowicz/' + name">
        </li>
      </ul>
      <ul v-if="npm && npmName.length > 0">
        <li>
          <img :src="'https://img.shields.io/npm/v/' + npmName">
        </li>
        <li>
          <img :src="'https://img.shields.io/npm/dt/' + npmName">
        </li>
        <li>
          <img :src="'https://img.shields.io/npm/dm/' + npmName">
        </li>
        <li>
          <img :src="'https://img.shields.io/npm/dy/' + npmName">
        </li>
      </ul>
    </div>
    <div class="card-footer">
      <ul>
        <li>
          <img :src="'https://img.shields.io/github/repo-size/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/github/languages/code-size/milanowicz/' + name">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GitHubSourceCode',
  props: {
    name: String,
    bashLogo: {
      type: Boolean,
      default: false
    },
    build: {
      type: Boolean,
      default: false
    },
    buildName: {
      type: String,
      default: 'Build'
    },
    branch: {
      type: String,
      default: 'master'
    },
    coverageCode: {
      type: Boolean,
      default: false
    },
    coverageMutation: {
      type: Boolean,
      default: false
    },
    jsBadges: {
      type: Boolean,
      default: false
    },
    license: {
      type: Boolean,
      default: false
    },
    npmName: {
      type: String,
      default: ''
    },
    npm: {
      type: Boolean,
      default: false
    },
    npmLogo: {
      type: Boolean,
      default: false
    },
    php: {
      type: Boolean,
      default: false
    },
    phpLogo: {
      type: Boolean,
      default: false
    },
    pythonLogo: {
      type: Boolean,
      default: false
    },
    tag: {
      type: Boolean,
      default: true
    }
  }
};
</script>
