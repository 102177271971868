<template>
  <div class="card">
    <div class="card-header">
      <a :href="'https://github.com/Milanowicz/' + name" target="_blank">{{ label }}</a>
    </div>
    <img v-if="pythonLogo" src="../assets/images/python-logo-inkscape.svg" class="card-img-top">
    <div class="card-body">
      <ul>
        <li>
          <img :src="'https://img.shields.io/github/repo-size/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/github/languages/code-size/milanowicz/' + name">
        </li>
        <li>
          <img :src="'https://img.shields.io/github/last-commit/Milanowicz/' + name">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GitHubDataSet',
  props: {
    name: String,
    label: String,
    pythonLogo: {
      type: Boolean,
      default: false
    }
  }
};
</script>
