<template>
  <div>
    <h2>Web Development</h2>
    <div class="row m-0">
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="php-testing"
          :build="true"
          build-name="Testing"
          :coverage-code="true"
          :coverage-mutation="true"
          :php="true"
          :php-logo="true"
          :license="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="php-thread"
          :build="true"
          build-name="Testing"
          :coverage-code="true"
          :coverage-mutation="true"
          :php="true"
          :php-logo="true"
          :license="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="BrowserDetection.js"
          :build="true"
          :coverage-code="true"
          :coverage-mutation="true"
          :npm="true"
          :npm-logo="true"
          npm-name="browser-detection.js"
          :js-badges="true"
          :license="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xxl-3 col-lg-4 col-md-6 col-12">
        <GitHubSourceCode
          name="CSSSassCollection"
          npm-name="csssasscollection"
          :build="true"
          :npm="true"
          :npm-logo="true"
        ></GitHubSourceCode>
      </div>
    </div>

    <h2>Data Science</h2>
    <div class="row m-0">
      <div class="col-md-6">
        <GitHubDataSet
          name="COVID-19-Dataset"
          label="COVID-19 Dataset"
          :python-logo="true"
        ></GitHubDataSet>
      </div>
      <div class="col-md-6">
        <GitHubDataSet
          name="COVID-19-RKI"
          label="COVID-19 Robert Koch-Institut Dataset"
          :python-logo="true"
        ></GitHubDataSet>
      </div>
    </div>

    <h2>Administration</h2>
    <div class="row m-0 pb-5">
      <div class="col-xl-4 col-md-6 col-12">
        <GitHubSourceCode
          name="GitHooks"
          :tag="false"
          :license="true"
          :bash-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xl-4 col-md-6 col-12">
        <GitHubSourceCode
          name="GitJobs"
          :tag="false"
          :license="true"
          :bash-logo="true"
        ></GitHubSourceCode>
      </div>
      <div class="col-xl-4 col-md-6 col-12">
        <GitHubSourceCode
          name="SyncDB.sh"
          :license="true"
          :bash-logo="true"
        ></GitHubSourceCode>
      </div>
    </div>
  </div>
</template>

<script>
import GitHubSourceCode from '@/components/GitHubSourceCode';
import GitHubDataSet from '@/components/GitHubDataSet';
export default {
  name: 'ViewSource',
  components: {
    GitHubDataSet,
    GitHubSourceCode
  }
};
</script>
